import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import Link from 'next/link';
import CommonParentSection from './CommonParentSection';
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai"
import { HomepageBrandLoader } from '../../../common_components/Loader/Index';
import Image from 'next/image';
import Analytics from '../../../common_components/Common/Analytics';

function PopularBrands() {
  
  const { toptencompanies, toptencompaniesLoading } = useSelector((state) => state.home); 
  const [companies, setCompanies] = useState([]);
  const [count, setCount] = useState(6);
  const [current, setcurrent] = useState(10);
  

  const HandleScroll = (direction) => {
    var container = document.getElementById('container')
    if (direction === 'left') {
      container.scrollLeft -= 187;
      if (count > 6) {
        setCount(count - 1);
      }
    } else {
      container.scrollLeft += 187;

      // if(count < 4) {
      setCount(count + 1);
      // }
    }
  }

  useEffect(() => {
    if (count % 10 === 0) {
      if (count >= current) {
        let companyArray = [];
        companies.slice(0, 10).map((element) => {
          companyArray.push(element);
        })
        let finalArray = companies.concat(companyArray);
        setCompanies(finalArray)
        setcurrent(count);
      }
      else {
        let companyArray = [...companies];
        let finalArray = companyArray.filter((element, index) => index < companyArray.length - 10)
        setCompanies(finalArray);
        if (count === 10) {
          setcurrent(count)
        }
      }
    }
  }, [count])
  const HandleEvents = (data) => {
    const Analyticsprops = {
      GA: true,
      title :`Popular Brands-Events`, 
      category: 'Home Page-Events',
      action: `Brand logo`,
      label: `${data} logo clicked!`,
    }
    Analytics(Analyticsprops)
  }





  const renderBrands = useMemo(
    () =>
      toptencompanies && !toptencompaniesLoading && (toptencompanies.map((companylogo) => (
        <Link href={`/electric-vehicle-companies/${companylogo.slug}`}>
          <a className='me-3 me-md-5 brand-logo' >
            <Image
              src={companylogo.logo}
              alt='brand-img'
              sizes="320 640 750"
              layout='fixed'
              width="140px"
              height="140px"
              className='brand-img'
              loading='lazy'
              onClick={() => HandleEvents(companylogo.title)}
            />
          </a>
        </Link>
      ))),
    [toptencompanies, companies]
  );
  return (
    <CommonParentSection title={'Popular Electric Brands'} type={'brands'}>
      <div className='position-relative scroll-container-hide mt-2' >
        {toptencompanies.length > 0 && !toptencompaniesLoading ?
          <div className='' >
            <div className='d-flex justify-content-between align-items-center flex-row scroll-container scroll-container-full' id="container" >
              {renderBrands}
            </div>
            <button
              className='scroll-btn scroll-btn-left scroll-btn-position d-none d-xl-block'
              onClick={() => HandleScroll("left")}
              // style={{ top: "3rem" }}
              aria-label="Scroll to popular Brands on the left"
            >
              <AiOutlineLeft id='popular-brand-left' className='Right-left-icon' />
            </button>
            <button
              className='scroll-btn scroll-btn-right scroll-btn-right-position d-none d-xl-block'
              onClick={() => HandleScroll("right")}
              // style={{ top: "3rem" }}
              aria-label="Scroll to popular Brands on the right"
            >
              <AiOutlineRight id='popular-brand-right' className='Right-left-icon' />
            </button>
          </div> : <HomepageBrandLoader />}
      </div>
    </CommonParentSection>
  );
}

export default PopularBrands;

